import { TStorageKeys } from '../types';

export class StorageClass {
  private _storageInstance: Storage;

  constructor(storageInstance: Storage) {
    this._storageInstance = storageInstance;
  }

  getItem<TKeys = void>(key: TStorageKeys<TKeys> & string) {
    if (!this._storageInstance) {
      return null;
    }

    const item = this._storageInstance.getItem(key);

    // Обработка кейсов null | undefined | ''
    if (!item) {
      return item;
    }

    try {
      // Отлично парсит всё кроме простых строк
      return JSON.parse(item);
    } catch {
      // В случае попытки парсинга обычной строки парадет ошибка
      // В данном случае просто возвращается эта строка
      return item;
    }
  }

  setItem<TKeys = void>(key: TStorageKeys<TKeys> & string, value: any) {
    if (!this._storageInstance) {
      return;
    }

    if (value === undefined) {
      this._storageInstance.removeItem(key);
    } else {
      this._storageInstance.setItem(key, JSON.stringify(value));
    }
  }

  removeItem(key: string) {
    if (!this._storageInstance) {
      return;
    }

    this._storageInstance.removeItem(key);
  }
}
