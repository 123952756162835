import { StorageClass } from './classes/StorageClass';
import { STORAGE_KEYS_TO_REMOVE } from './consts';

export * from './types';
export * from './consts';
export const persistentLocalStorage = new StorageClass(window?.localStorage);
export const persistentSessionStorage = new StorageClass(
  window?.sessionStorage
);

// Метод для очистки старых ключей localStorage при старте
// Вызывается в корневом index.tsx
export const clearOldLocalStorage = () => {
  STORAGE_KEYS_TO_REMOVE.forEach((key) => {
    persistentLocalStorage.removeItem(key);
  });
};
